<template>
    <div>
        <div class="short-box" ref="shortList">
            <div class="short-btn customer-service" @click="showMask = !showMask" ref="customerService" @touchstart="!showMask && touchstartHandle('customerService',$event)" @touchmove="!showMask && touchmoveHandle('customerService',$event)" @touchend="!showMask && end('customerService')">
                <div class="short-list" :class="{ show: showMask, classFlag: classFlag, topPos: !classFlag}">
                    <div class="short-item" @click="gohistory()">
                        <p><van-icon class="iconfont icon-shouye" /></p>
                        <p>历史</p>
                        <p>报告</p>
                    </div>
                    <div class="short-item" @click="gobaseinfo()">
                        <p>健康</p>
                        <p>档案</p>
                    </div>

                </div>
                <!-- <van-icon class="iconfont" :class="{ 'icon-guanbi1': showMask, 'icon-caidan1': !showMask }" /> -->
                <svg v-if="showMask" t="1677725042316" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5044" width="30" height="30">
                    <path d="M297.805317 336.693984l452.336598 0c12.485309 0 22.514491-10.029182 22.514491-22.514491s-10.029182-22.514491-22.514491-22.514491L297.805317 291.665001c-12.485309 0-22.514491 10.029182-22.514491 22.514491S285.320008 336.693984 297.805317 336.693984z" p-id="5045"></path>
                    <path d="M296.781931 516.809914l452.336598 0c12.485309 0 22.514491-10.029182 22.514491-22.514491s-10.029182-22.514491-22.514491-22.514491L296.781931 471.780931c-12.485309 0-22.514491 10.029182-22.514491 22.514491S284.296622 516.809914 296.781931 516.809914z" p-id="5046"></path>
                    <path d="M750.141915 647.803318 297.805317 647.803318c-12.485309 0-22.514491 10.029182-22.514491 22.514491s10.029182 22.514491 22.514491 22.514491l452.336598 0c12.485309 0 22.514491-10.029182 22.514491-22.514491S762.627224 647.803318 750.141915 647.803318z" p-id="5047"></path>
                    <path d="M202.221067 336.693984l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C224.735559 326.562463 214.604038 336.693984 202.221067 336.693984z" p-id="5048"></path>
                    <path d="M202.221067 516.809914l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C224.735559 506.678393 214.604038 516.809914 202.221067 516.809914z" p-id="5049"></path>
                    <path d="M202.221067 692.832301l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C224.735559 682.70078 214.604038 692.832301 202.221067 692.832301z" p-id="5050"></path>
                    <path d="M856.676394 338.740756l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C879.190885 328.609234 869.059364 338.740756 856.676394 338.740756z" p-id="5051"></path>
                    <path d="M856.676394 518.856686l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C879.190885 508.725165 869.059364 518.856686 856.676394 518.856686z" p-id="5052"></path>
                    <path d="M856.676394 694.879073l-13.304018 0c-12.38297 0-22.514491-10.131521-22.514491-22.514491l0 0c0-12.38297 10.131521-22.514491 22.514491-22.514491l13.304018 0c12.38297 0 22.514491 10.131521 22.514491 22.514491l0 0C879.190885 684.747551 869.059364 694.879073 856.676394 694.879073z" p-id="5053"></path>
                </svg>
                <svg v-else t="1677724858731" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4754" width="30" height="30">
                    <path
                        d="M394.72 221.18H169.44c-25.31 0-45.95 20.64-45.95 45.95V492.4c0 25.31 20.64 45.95 45.95 45.95h225.27c25.4 0 45.95-20.64 45.95-45.95V267.13c0.01-25.31-20.63-45.95-45.94-45.95z m3.08 271.23c0 1.68-1.4 3.08-3.08 3.08H169.44c-1.68 0-3.08-1.4-3.08-3.08V267.13c0-1.68 1.4-3.08 3.08-3.08h225.27c1.68 0 3.08 1.4 3.08 3.08v225.28zM394.72 579.08H169.44c-25.31 0-45.95 20.64-45.95 45.95V850.3c0 25.31 20.64 45.95 45.95 45.95h225.27c25.4 0 45.95-20.55 45.95-45.95V625.03c0.01-25.31-20.63-45.95-45.94-45.95z m3.08 271.32c0 1.68-1.4 3.08-3.08 3.08H169.44c-1.68 0-3.08-1.4-3.08-3.08V625.12c0-1.68 1.4-3.08 3.08-3.08l225.27-0.09c1.68 0 3.08 1.4 3.08 3.08V850.4zM760.65 579.08H535.37c-25.31 0-45.95 20.64-45.95 45.95V850.3c0 25.31 20.64 45.95 45.95 45.95h225.27c25.31 0 45.95-20.55 45.95-45.95V625.03c0.01-25.31-20.63-45.95-45.94-45.95z m3.07 271.32c0 1.68-1.4 3.08-3.08 3.08H535.37c-1.68 0-3.08-1.4-3.08-3.08V625.12c0-1.68 1.4-3.08 3.08-3.08l225.27-0.09c1.68 0 3.08 1.4 3.08 3.08V850.4zM886.92 300.01L728.24 141.33c-18.12-18.12-47.72-18.12-65.84 0L503.71 300.11c-18.12 18.12-18.12 47.72 0 65.84l158.68 158.68c9.15 9.06 21.01 13.64 32.97 13.64a46.51 46.51 0 0 0 32.88-13.64l158.68-158.77c18.12-18.12 18.12-47.73 0-65.85z"
                        fill="#050101" p-id="4755"></path>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showMask: false,
            touch: {
                initialPosition: {
                    x: 0,
                    y: 500,
                },
                movePostion: {},
            },
            element: {
                initialPosition: {},
                movePostion: {},
            },
            nowLeft: -1,
            classFlag: false,
        };
    },
    methods: {
        gobaseinfo() {
            this.$router.push({ path: "/healthrecord" });
        },
        gohistory() {
            this.$router.push({ path: "/history" });
        },
        touchstartHandle(refName, e) {
            // 触摸开始
            let touchTarget = e.targetTouches[0];
            // 记录触摸点的坐标（初始位置）
            this.touch.initialPosition = {
                x: touchTarget.clientX,
                y: touchTarget.clientY,
            };
            // 记录需要移动的元素坐标（初始位置）
            this.element.initialPosition = {
                x: this.$refs[refName].offsetLeft,
                y: this.$refs[refName].offsetTop,
            };
        },
        touchmoveHandle(refName, e) {
            e.preventDefault();
            let touchTarget = e.targetTouches[0];
            let X =
                this.element.initialPosition.x +
                (touchTarget.clientX - this.touch.initialPosition.x);
            let Y =
                this.element.initialPosition.y +
                (touchTarget.clientY - this.touch.initialPosition.y);
            let maxWidth = innerWidth - this.$refs[refName].offsetWidth;
            let maxHeight = innerHeight - this.$refs[refName].offsetHeight;
            X = X <= 0 ? 0 : X >= maxWidth ? maxWidth : X;
            Y = Y <= 0 ? 0 : Y >= maxHeight ? maxHeight : Y;
            this.nowLeft = X;
            this.classFlag = Y < 300;
            // 移动元素
            this.$refs[refName].style.left = X + "px";
            this.$refs[refName].style.top = Y + "px";
        },
        end(refName) {
            if (this.nowLeft < 0) {
                return;
            }
            // 吸边处理
            let halfMaxWidth =
                (innerWidth - this.$refs[refName].offsetWidth) / 2;
            const dragDiv = this.$refs[refName];
            if (this.nowLeft > halfMaxWidth) {
                // 右吸边
                dragDiv.style.left =
                    document.documentElement.clientWidth -
                    parseInt(dragDiv.clientWidth) -
                    10 +
                    "px";
            } else {
                // 左吸边
                dragDiv.style.left = "10px";
            }
            dragDiv.style.top = dragDiv.offsetTop + "px";
        },
    },
};
</script>
<style lang="less">
.customer-service {
    position: fixed;
    right: 20px;
    width: 88px;
    bottom: 180px;
    z-index: 9999;
}
.short-btn {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    text-align: center;
    background: linear-gradient(321deg, #4575f4 0%, #5b85f2 30%, #79c1ff 100%);
    box-shadow: 0px 3px 5px 0px #b4c8ff;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 44px;
        border-radius: 50%;
        color: #fff;

        &.icon-guanbi1 {
            font-size: 30px;
        }
    }
}

.short-list {
    width: 55px;
    background: #e0ebf7;
    border-radius: 48px;
    margin-bottom: 20px;
    box-sizing: border-box;
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height ease-out 0.4s;
    position: absolute;
    &.topPos {
        bottom: 44px;
    }
    &.classFlag {
        top: 66px;
    }
    .short-item {
        text-align: center;
        padding: 12px 0;
        p {
            font-size: 14px;
            color: #999999;
            margin: 0;
            line-height: 20px;

            i {
                font-size: 22px;
                color: #0179ff;
                margin-bottom: 5px;
            }
        }

        + .short-item {
            border-top: 1px solid #c4d3e4;
        }
    }

    &.show {
        max-height: 500px;
        transition: max-height ease-out 0.4s;
    }
}
</style>