<template>
    <div>
        <div :class="organ" :style="{ zoom }"></div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 500,
        },
        organ: {
            type: String,
            range: ["heart", "liver", "stomach", "lung", "renal"],
        },
    },
    computed: {
        zoom() {
            return this.width / 500;
        },
    },
};
</script>

<style scoped>
.heart,
.liver,
.stomach,
.lung,
.renal {
    width: 500px;
    height: 500px;
}
.heart {
    background-image: url(../assets/heart.png);
    animation: heart 4s steps(62) infinite;
}
.liver {
    background-image: url(../assets/liver.png);
    animation: liver 4s steps(48) infinite;
}
.stomach {
    background-image: url(../assets/stomach.png);
    animation: stomach 4s steps(49) infinite;
}
.lung {
    background-image: url(../assets/lung.png);
    animation: lung 4s steps(53) infinite;
}
.renal {
    background-image: url(../assets/renal.png);
    animation: renal 4s steps(49) infinite;
}
@keyframes heart {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -31000px 0;
    }
}
@keyframes liver {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -24000px 0;
    }
}
@keyframes stomach {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -24500px 0;
    }
}
@keyframes lung {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -26500px 0;
    }
}
@keyframes renal {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -24500px 0;
    }
}
</style>
