<template>
    <div>
        <div class="brain" :style="{ zoom }"></div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 320,
        },
    },
    computed: {
        zoom() {
            return this.width / 320;
        },
        // height() {
        //     return (this.width / 640) * 512;
        // },
    },
};
</script>

<style scoped>
.brain {
    background-image: url(../assets/brain.png);
    animation: brain 4s steps(68) infinite;
    height: 280px;
    width: 320px;
}
@keyframes brain {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -21760px 0;
    }
}
</style>
